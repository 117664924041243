import React from 'react';
import styles from './Ads.module.scss'; // Create and import a corresponding stylesheet

type Props = {
  imageUrl: string,
  redirectUrl: string,
};

const Ads = ({ imageUrl, redirectUrl }: Props) => {
  return (
    <div className={styles['ads']}>
      <a href={redirectUrl} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt="Advertisement" className={styles['ads__image']} />
      </a>
    </div>
  );
};

export default Ads;
